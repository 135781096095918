import { DayOfWeek } from './time'
import { Address } from './address'
import { Geolocation } from './geolocation'
import { Currency } from './ticket'
import { Tokens, UserRole } from './customer'
import { CreditCard } from './creditCards'
import { CustomerTokens } from './customer'

export enum LotType {
  Business = 1,
  Residential = 2,
}

export enum CustomStylesValue {
  Url = 1,
  Color = 2,
}
export enum CustomStylesElement {
  Header = 1,
  CardBackground = 2,
}
export interface LotCustomStyles {
  value: string
  valueType: CustomStylesValue
  elementType: CustomStylesElement
}
export interface OpeningHoursInDay {
  startTime: string
  endTime: string
}

export interface OpeningHours {
  dayOfWeek: DayOfWeek
  openingHoursInDay: OpeningHoursInDay[]
}

export interface GetMapLots {
  northEastBoundLat: number
  northEastBoundLng: number
  southWestBoundLat: number
  southWestBoundLng: number
}

export interface Spot {
  isEnabled: boolean
  minSpotNumber?: number
  maxSpotNumber?: number
}

export enum OptionType {
  UntilNextDayStart = 0,
  Hrs24 = 1,
  ToSpecificTime = 2,
  MultiIncrementalSubtype = 3,
  MultiChoiceSubtype = 4,
}
export enum PricingType {
  NoT2P = 0,
  Mix = 1,
  TPP = 2,
  Multi = 3,
  Residential = 101,
}

export enum ExternalPricingType {
  NoIntegration = 0,
  T2 = 1,
}
export interface SpecialPriceChoice {
  type: PricingType
  endDateTime: string
  specialLabelChoice: string
  amount: number
  convenienceFee?: number
  currency: string
}

export interface TimeSelectOption {
  pricingType: PricingType
  optionType?: OptionType
  optionLabel?: string
  optionId?: string
  showAllDayUntil: boolean
  allDayUntilMessage?: string
  endDateTime?: string
  specialLabelChoice?: string
  amount?: number
  convenienceFee?: number
  choiceId?: string
  intervalOptions?: IntervalOptions
}

export interface IntervalOptions {
  showDaySelect: boolean
  showHourSelect: boolean
  showMinuteSelect: boolean
  dayInterval?: number
  maxDays?: number
  hourInterval?: number
  maxHours?: number
  minuteInterval?: number
  maxMinutes?: number
  maxTotalMinutes?: number
}

export interface Lot {
  lotType: LotType
  lotId: string
  lotName: string
  locationName?: string
  locationId: number
  logoLink?: string
  geolocation?: Geolocation
  redirectUrl?: string | null
  customStyles: LotCustomStyles[]
}
export interface MonthlyParkerInfo {
  parkerMonthlyId: string
  expireMonthlyParkingDateTime: string
}

export interface SimulatedPrice {
  parkingLotId: string
  simulatedPrice?: number
  currency?: Currency
  isPriceFound: boolean
}
/**
 * API CALLS
 */
export interface GetLotsPayload {
  token?: string
  northEastBoundLat?: number
  northEastBoundLng?: number
  southWestBoundLat?: number
  southWestBoundLng?: number
  pcode?: string
}

export interface GetLotData extends Lot {
  pCode: string
}
export interface GetLotsData {
  lots: GetLotData[]
  newTokens: {
    tokens: Tokens
    role: UserRole
  }
}

export interface GetLotDetailsMapPayload {
  lotId?: string
  pCode?: string
}

export interface LotCapacity {
  capacityFullSMSMessage: string
  capacityFullWEBMessage: string
  lotIsFull: boolean
}

export interface GetLotDetailsMapData extends Lot {
  address: Address
  openingHours: OpeningHours[]
  logoLink?: string
  currentDateTimeLocal: string
  pCode: string
  isLocationStopped: boolean
  locationStoppedMessage: string | null
  lotCapacity: LotCapacity | null
}

export interface GetLotDetailsParkPayload {
  lotId?: string
  token?: string
  carId?: string
}
export type GetLotDetailsParkData = Omit<Lot, 'geolocation'> & {
  currency: Currency
  creditCardUsed?: Pick<CreditCard, 'cardType' | 'last4Digits' | 'creditCardId'>
  logoLink?: string
  monthlyEnable: boolean
  parkerId: string
  monthlyParkerInfo?: MonthlyParkerInfo
  spot: Spot
  timeSelectOption?: TimeSelectOption[]
  newTokens?: CustomerTokens
  currentDateTimeLocal: string
  corporationLotsToken?: string
  /** sms flow; car is already parked, and user clicks the same link  */
  carId?: string
  isExternalPricingEnabled: boolean
  externalPricingType: ExternalPricingType
  selfRegisterUrl?: string | null
  isLocationStopped: boolean
  locationStoppedMessage: string | null
  lotCapacity: LotCapacity | null
  isUpfrontCouponAvailable: boolean
  redirectUrl?: string | null
}
export interface GetSimulatedPricesRequest {
  /** lot ids */
  parkings: string[]
  /** format: "2020-09-28T18:00:00" */
  simulateStartTime: string
  /** format: "2020-09-28T18:00:00" */
  simulateEndTime: string
}
export type GetSimulatedPricesResponse = SimulatedPrice[]
