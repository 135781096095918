import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'
import AdapterMoment from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import ErrorsWrapper from 'ErrorsWrapper'
import { useDebugRouter, useGeolocation, useRefreshSession } from 'hooks'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import AppRoutes from 'routing/AppRoutes'
import { AppPopupProvider, AppSnackBarProvider } from 'shared'
import { CounterProvider } from 'shared/CounterProvider/CounterProvider'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { GlobalStyle, theme } from 'themes'
import TermsAndConditions from './pages/auth/terms_and_conditions/TermsAndConditions'
import GlobalPopup from './pages/global_popup/GlobalPopup'
import './reset.css'
import Loader from './shared/loader/Loader'
import i18n from 'i18n'
import moment from 'moment'
import 'moment/locale/es'
import { useTranslation } from 'react-i18next'
import AutoLogout from '../src/shared/autoLogout/AutoLogout'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { CAPTCHA_V3_KEY } from 'api/config'

const App: React.FC<RouteComponentProps> = props => {
  const [initLocation] = React.useState(props.location.pathname)
  const { turnOnGeo } = useGeolocation()
  const { t } = useTranslation()
  useRefreshSession(initLocation)
  useDebugRouter()
  React.useEffect(() => {
    turnOnGeo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])
  React.useEffect(() => {
    moment.locale(i18n.resolvedLanguage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.resolvedLanguage])

  return (
    <>
      <GlobalStyle />
      <StyledEngineProvider injectFirst>
        <StyledThemeProvider theme={theme}>
          <MuiThemeProvider theme={theme}>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              locale={i18n.language}
            >
              <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_V3_KEY} >
              <ErrorsWrapper>
                <AppSnackBarProvider>
                  <AppPopupProvider>                    
                    <CounterProvider>
                      <AutoLogout>
                        <AppRoutes />
                      </AutoLogout>
                    </CounterProvider>
                    <TermsAndConditions />
                    <GlobalPopup />
                    <Loader />
                  </AppPopupProvider>
                </AppSnackBarProvider>
              </ErrorsWrapper>
              </GoogleReCaptchaProvider>
            </LocalizationProvider>
          </MuiThemeProvider>
        </StyledThemeProvider>
      </StyledEngineProvider>
    </>
  )
}

export default App
