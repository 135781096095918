import { ThemeProvider } from '@mui/material/styles'

import { unwrapResult } from '@reduxjs/toolkit'
import { useChangeLayout } from 'hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { paths } from 'routing'
import { AppSpinner, PaddingContainer, PageHeading } from 'shared'
import { usePopup } from 'shared/popup_provider/AppPopupProvider'
import { customerSlice, parkCarSlice, ticketsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { whiteTheme } from 'themes'
import { ErrorCode } from 'typedef'
import { Translations } from 'utils'

export interface ParkCarProps {}

export const ParkCarSmsPage: React.FC<ParkCarProps> = props => {
  const [loading, setLoading] = React.useState(false)
  const dispatch = useAppDispatch()
  const { setPopup, showPopup } = usePopup()
  useChangeLayout()
  const { t } = useTranslation()
  const { push, location } = useHistory()
  const { token } = useParams<{ token: string }>()

  const fetchTicket = async (carId: string) => {
    setLoading(true)
    const promise = await dispatch(ticketsSlice.getTicket({ carId }))
    if (ticketsSlice.getTicket.fulfilled.match(promise)) {
      dispatch(
        ticketsSlice.actions.setSelectedTicket({
          ticketId: carId,
        })
      )

      const addMoreTime = location.pathname.match(new RegExp(paths.sms.addTime))

      addMoreTime
        ? push(`${paths.parking.addTime}/${carId}`)
        : push(paths.parking.tickets)
    } else {
      setLoading(false)
      setPopup({
        ...promise.payload,
        disableBackdropClick: true,
        buttons: [
          { onClick: () => fetchTicket(carId) },
          {
            onClick: () => {
              dispatch(customerSlice.actions.destroySession())
              push(paths.auth.login)
            },
            text: t(Translations.BACK_TO_LOGIN),
          },
        ],
      })
      showPopup(true)
    }
  }
  const fetchLot = React.useCallback(async () => {
    setLoading(true)
    const promise = await dispatch(parkCarSlice.getLotDetailsPark({ token }))
    if (parkCarSlice.getLotDetailsPark.fulfilled.match(promise)) {
      const res = unwrapResult(promise)
      if (res.carId) {
        fetchTicket(res.carId)
      } else {
        if (res.redirectUrl) {
          window.location.href = res.redirectUrl
        } else push(`${paths.parking.parkCar}/${res.lotId}`)
      }
    } else {
      setLoading(false)
      setPopup({
        ...promise.payload,
        disableBackdropClick: true,
        buttons: [
          promise.payload.code === ErrorCode.TokenExpired
            ? undefined
            : { onClick: () => fetchLot() },
          {
            onClick: () => {
              dispatch(customerSlice.actions.destroySession())
              push(paths.auth.login)
            },
            text: t(Translations.BACK_TO_LOGIN),
          },
        ],
      })
      showPopup(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token, push])

  React.useEffect(() => {
    fetchLot()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <ThemeProvider theme={whiteTheme}>
      <PaddingContainer>
        <PageHeading title={t(Translations.PARKING_MADE_EASY)} />
        {loading && <AppSpinner show relative height="150px" />}
      </PaddingContainer>
    </ThemeProvider>
  )
}
export default ParkCarSmsPage
